import {
  FormControl,
  Switch,
  CardContent,
  Card,
  Typography,
  Stack, ListItemSecondaryAction, IconButton, ButtonGroup, Button, Select, MenuItem, CardHeader, InputLabel
} from "@mui/material";
import { Controller, useController, useFormContext, useFormState } from "react-hook-form";
import Divider from "@mui/material/Divider";
import { StrategyType } from "./deal";
import GeoSearchAutocomplete from "./geoSearchAutocomplete";
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import { IncludeExcludeOverlay } from "./strategyOverlay"
import { useContext, useEffect, useState } from "react";
import BulkDialog from "../shared/bulkDialog";
import { SnackbarNotificationsContext } from '@dtx/ui-core/src/context/SnackbarNotificationsProvider';

interface GeographicTargetingProps {
  control: any;
  setValue: Function
  getValues: Function
  selectedGeoSearchItems: any
  strategyGeoValue: StrategyType
  showNotification: (type: string, message: string) => void;
}

export interface GeoSearchItem {
  "result-type": string
  "country-name": string
  "country-iso-code": string
  "sub-1-iso": string
  "sub-1-name": string
  "sub-2-iso": string
  "sub-2-name": string
  "metro-code": string
  "city-name": string
  "display-name": string
}

export default function GeographicTargeting( props:GeographicTargetingProps) {
  const { control, setValue, getValues, selectedGeoSearchItems, strategyGeoValue } = props
  const [openDialog, setOpenDialog] = useState(false)

  const queryTypeMap = {
    "Country": "country",
    "World Region": "world-region",
    "State": "state",
    "DMA": "dma",
    "City": "city",
    "Zip Code": "zip-code"
  };

  interface RequestBody {
    values: string[];
    country?: string;
  }

  const [progress, setProgress] = useState<number>(0);
  const [isMatching, setIsMatching] = useState(false);
  
  const fetchBulkData = async (parsedValues: string[], dropdownValue: string, country?: string) => {
    
    try {
      const chunkSize = 100; // Adjust this value if needed
      const chunks = [];
      for (let i = 0; i < parsedValues.length; i += chunkSize) {
        chunks.push(parsedValues.slice(i, i + chunkSize));
      }

    const results = [];
    setProgress(0);
    setIsMatching(true);

    for (let i = 0; i < chunks.length; i++) {
      try {
        const chunk = chunks[i];
        const queryType = queryTypeMap[dropdownValue] || dropdownValue;

        let requestBody: RequestBody = { values: chunk };

        if (country && country.trim() !== "") {
          requestBody.country = country;
        }

        setProgress(((i + 1) / chunks.length) * 100);

        const response = await fetch(`/api/geo/get-bulk-${queryType}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        if (Array.isArray(responseData)) {
          results.push(...responseData);
        } else if (typeof responseData === 'object' && responseData !== null) {
          results.push(...Object.values(responseData));
        } else {
          console.error("Unexpected response data format:", responseData);
          showNotification("error", "Unexpected response data format");
        };
        } catch (error) {
        console.error("Error fetching data:", error);
        showNotification("error", `Error fetching data for chunk ${i + 1}`);
      }
    }

    setIsMatching(false);
    return results;
  
  } catch (error) {
    console.error("Error fetching bulk data:", error);
    showNotification("error", "Error fetching bulk data");
  } finally {
    setIsMatching(false);
  }
  };

  const hierarchyOrder = {
    "country": 1,
    "region": 2,
    "state": 3,
    "city": 4,
    "metro": 5,
    "postal_code": 6
  };

  const fetchExistingGeo = async () => {
    const response = await fetch(`/api/geo/get-existing-geo`, {
      method: "post",
      mode: "cors",
      body: JSON.stringify(selectedGeoSearchItems)
    })
    const json = await response.json();
    return json
  }

  const [geoAutocompleteKey, setGeoAutocompleteKey] = useState(0);

  const { showNotification } = useContext(SnackbarNotificationsContext)

  const handleOptionSelect = (item: GeoSearchItem | null, bulkUpload?: boolean) => {
    if (item) {
      setIsMatching(true);
      try {

      let rawCurrentItems = bulkUpload ? getValues("deal.conditions-json.geo.value") : selectedGeoSearchItems || [];
      const currentItems = Array.isArray(rawCurrentItems) ? rawCurrentItems : [rawCurrentItems];
      const itemHierarchyLevel = hierarchyOrder[item["result-type"]];
      let updatedItems = [...currentItems];
      let removedItems = [];
      let duplicateItems = [];

      if (item["result-type"] === 'country') {
        updatedItems = updatedItems.filter(existingItem => {
          // Remove if existingItem is a child of the selected country
          const isChild = existingItem["country-iso-code"] === item["country-iso-code"];
          if (isChild) {
            removedItems.push(existingItem);
          }
          return !isChild;
        });
      } else {
        // Remove the country if a child is being added, and then check for siblings
        updatedItems = updatedItems.filter(existingItem => {
          // Remove if existingItem is the country of the selected item
          if (existingItem["result-type"] === 'country' && existingItem["country-iso-code"] === item["country-iso-code"]) {
            removedItems.push(existingItem);
            return false;
          }

          const existingItemHierarchyLevel = hierarchyOrder[existingItem["result-type"]];
          const isSameCountry = item["country-iso-code"] === existingItem["country-iso-code"];
          const isSameSubRegion = item["sub-1-iso"] === existingItem["sub-1-iso"];

          // Check if the item is a parent or child of any other items based on hierarchy
          const isParent = isSameCountry && isSameSubRegion && (itemHierarchyLevel > existingItemHierarchyLevel);
          const isChild = isSameCountry && isSameSubRegion && (itemHierarchyLevel < existingItemHierarchyLevel);

          if (isParent || isChild) {
            removedItems.push(existingItem);
          }

          return !isParent && !isChild;
        });
      }
      
      const isDuplicate = updatedItems.some(existingItem => existingItem["display-name"] === item["display-name"]);
      if (!isDuplicate) {
        updatedItems.push(item);
      } else {
        duplicateItems.push(item)
      }

      if (removedItems.length > 0) {
        const removedItemsNames = removedItems.map(item => item["display-name"]).join(', ');
        showNotification("info", `Items removed due to hierarchy: ${removedItemsNames}`);
      }

      if (duplicateItems.length > 0) {
        const duplicateItemsNames = duplicateItems.map(item => item["display-name"]).join(', ');
        showNotification("info", `Item not added due to duplication: ${duplicateItemsNames}`);
      }

      setValue("deal.conditions-json.geo.value", updatedItems);
      setGeoAutocompleteKey(prev => prev + 1);
    } catch (error) {
      showNotification("error", `Error adding item: ${error}`);
    } finally {
      setIsMatching(false);
    }

    }
    
  };

  useEffect(() => {
    const fetchData = async () => {
      const result: GeoSearchItem[] = await fetchExistingGeo()
      setValue("deal.conditions-json.geo.value", result);
    };
    selectedGeoSearchItems.length !== 0 ? fetchData() : null
  }, []);
 
  function renderGeoRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const item = selectedGeoSearchItems[index];
    if (!item) {
      return null;
    }

    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={item["display-name"]} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => deleteItem(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
    );
  }

  const deleteItem = (itemIndex:number) => {
    const currentItems = selectedGeoSearchItems || [];

    const updatedItems = currentItems.filter((_, index) => index !== itemIndex);

    setValue("deal.conditions-json.geo.value", updatedItems, { shouldValidate: true });
  };

  const name = "Geographic Targeting"
  return (

      <Card variant={"outlined"} style={{ marginTop: 12 }}>
        <CardHeader titleTypographyProps={{ variant: "caption" }} title={name} />
        <CardContent>
     <IncludeExcludeOverlay {...props} strategyKey={"deal.conditions-json.geo.strategy"} name={name} >
      <Stack direction="row" spacing={1} alignItems="center">
        <Controller
          name="deal.conditions-json.geo.strategy"
          control={control}
          render={({ field }) => (
            <FormControl style={{width: 140}}>
              <InputLabel id="geo-include-exclude-label">Include/Exclude</InputLabel>
            <Select labelId={"geo-include-exclude-label"} id={"geo-include-exclude"} {...field} value={field.value} label="Include/Exclude" onChange={() => {
                  const value = field.value === StrategyType.include
                    ? StrategyType.exclude
                    : StrategyType.include;
                  field.onChange(value);
            }}>
              <MenuItem value={StrategyType.include}>Include</MenuItem>
              <MenuItem value={StrategyType.exclude}>Exclude</MenuItem>
            </Select>
            </FormControl>
          )}
        />
        <Controller
          name="deal.conditions-json.geo.value"
          control={control}
          render={({ field }) =>  (
            <>
            <GeoSearchAutocomplete
              key={geoAutocompleteKey}
              selectedOption={selectedGeoSearchItems}
              onOptionSelect={(item) => {
                field.onChange(item)
                return handleOptionSelect(item)
              }}
            />
                <Button onClick={() => setOpenDialog(true)}>
                  Add geos in bulk
                </Button>
                {openDialog ? (
                  <BulkDialog open={openDialog} optionsSelect = {["Country","World Region","State","DMA","City","Zip Code"]} displayName={"display-name"} fetchData={fetchBulkData}  setOpen={setOpenDialog} title={"Geo Targeting"} control={control} selectedData={selectedGeoSearchItems}  handleSave={handleOptionSelect}/>
                ) : null}
            </>
          )}
        />
      </Stack>
      <FixedSizeList
        height={300}
        itemSize={46}
        style={{ border: `1px solid ${strategyGeoValue === 'exclude'  ? 'red' :strategyGeoValue === 'include' ? 'green' : "black"}`, margin: '10px 0' }}
        itemCount={selectedGeoSearchItems.length}
        overscanCount={5}
        itemData={selectedGeoSearchItems}
      >
        {renderGeoRow}
      </FixedSizeList>
     </IncludeExcludeOverlay>
        </CardContent>
      </Card>
  )
}



