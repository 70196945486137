import { Controller } from 'react-hook-form';
import { useEffect, useState, useCallback } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Card,
  CardContent,
  Typography,
  TextField,
  Checkbox,
  Autocomplete,
  Chip,
  IconButton,
  InputAdornment, Grid, Paper, PaperProps, Switch, FormControlLabel
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import * as React from "react";
import { DSP, MarketplaceType, SetupCriteria } from "./pad";

interface PadSetupProps {
  control: any;
  setValue: Function;
  getValues: Function;
  marketplaceType: MarketplaceType;
  dsp: number;
  setupCriteria: SetupCriteria;
  setupCriteriaList: string[];
  isEditing: boolean;
  isActive: boolean;
}

export default function PadSetup(props: PadSetupProps) {
  const { control, setValue, getValues, marketplaceType, dsp, setupCriteria, setupCriteriaList, isEditing, isActive } = props;
  const [dsps, setDsps] = useState<{ dsp_id: number; dsp_name: string }[]>([]);
  const [seats, setSeats] = useState<{ seat_id: string }[]>([]);
  const [fetchSeats, setFetchSeats] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState<{ seat_id: string }[]>(setupCriteriaList.map(seat_id => ({ seat_id })));
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);


  const getNoOptionsText = () => {
    switch (true) {
      case loading:
        return "Loading...";
      case !inputValue.trim():
        return "Start typing to view results";
      case dataFetched && seats.length === 0:
        return `No results for "${inputValue}"`;
      default:
        return null;
    }
  };


  const CustomPaper: React.FC<PaperProps> = (props) => (
    <Paper {...props} style={{ ...props.style, maxHeight: 200, overflow: 'auto' }} />
  );

  type DivProps = React.HTMLProps<HTMLDivElement>;

  const CustomListboxComponent: React.FC<DivProps> = (props) => (
    <div {...props} style={{ ...props.style, maxHeight: 200, overflow: 'auto' }}>
      {props.children}
    </div>
  );

  const fetchDsp = async () => {
    const response = await fetch(`/api/pads/getDSPS`, {
      method: "get",
      mode: "cors",
    });
    const json = await response.json();
    setDsps(json);
  };

  const fetchSeatsAPI = useCallback(async (marketplaceType, dsp) => {
    if (!marketplaceType || !dsp) return;
    const response = await fetch(`/api/pads/getValidSeatIdsForPAD?MarketplaceType=${marketplaceType}&DSP=${dsp}`, {
      method: "get",
      mode: "cors",
    });
    const json = await response.json();
    setSeats(json);
  }, []);

  const fetchSeatsAPISearch = useCallback(
    async (marketplaceType, dsp, searchTerm) => {
      if (!marketplaceType || !dsp || !searchTerm) return;
      setLoading(true);
      const response = await fetch(`/api/pads/searchSeatsForPAD?MarketplaceType=${marketplaceType}&DSP=${dsp}&SearchTerm=${encodeURIComponent(searchTerm)}`, {
        method: "get",
        mode: "cors",
      });
      const json = await response.json();
      setSeats(json);
      setLoading(false);
      setDataFetched(true);
    },
    []
  );

  useEffect(() => {
    fetchDsp();
  }, []);

  useEffect(() => {
    if (marketplaceType && dsp && setupCriteria) {
      setFetchSeats(true);
      setSeats([]); // Reset setup criteria list options
      setValue("pad.setup_criteria_list", []); // Clear the selected setup criteria list
      fetchSeatsAPI(marketplaceType, dsp);
    } else {
      setFetchSeats(false);
    }
  }, [marketplaceType, dsp, setupCriteria, setValue]);

  useEffect(() => {
    const trimmedInputValue = inputValue.trim();
    if (trimmedInputValue.length > 0) {
      fetchSeatsAPISearch(marketplaceType, dsp, trimmedInputValue);
    }
  }, [inputValue, marketplaceType, dsp, fetchSeatsAPISearch]);

  return (
    <Card variant={"outlined"} style={{ marginTop: 12 }}>
      <CardContent>
        <Typography style={{ paddingTop: 12, paddingBottom: 24 }}>Pads</Typography>
        <Controller
          name="pad.pad_name"
          control={control}
          rules={{
            required: "Pad Rule Name is required",
            minLength: { value: 3, message: "Minimum length is 3" },
            maxLength: { value: 200, message: "Maximum length is 200" }
          }}
          render={({ field, fieldState }) => (
            <FormControl fullWidth margin="normal">
              <TextField
                {...field}
                style={{ backgroundColor: "white" }}
                label="Pad Rule Name"
                value={field.value || ""}
                error={!!fieldState.error}
                disabled={isEditing}
                helperText={fieldState.error ? fieldState.error.message : ""}
              />
            </FormControl>
          )}
        />
        <Controller
          name="pad.marketplace_type"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl fullWidth margin="normal" >
              <InputLabel id="marketPlace-tag-select-label">Marketplace</InputLabel>
              <Select
                labelId="marketPlace-tag-select-label"
                error={!!fieldState.error}
                disabled={isEditing}
                label="Marketplace"
                value={field.value ?? ''}
                onChange={(e) => {
                  field.onChange(e);
                }}
                ref={field.ref}
              >
                {Object.keys(MarketplaceType).map((key, index) => (
                  <MenuItem value={key} key={index}>
                    {MarketplaceType[key]}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          )}
        />
        <Controller
          name="pad.dsp"
          control={control}
          rules={{
            required: "DSP is required"}}
          render={({ field, fieldState }) => (
            <FormControl fullWidth margin="normal">
              <InputLabel id="dsp-tag-select-label">Select a DSP</InputLabel>
              <Select
                labelId="dsp-tag-select-label"
                error={!!fieldState.error}
                value={field.value || ""}
                disabled={isEditing}
                label="Select a DSP"
                onChange={(e) => {
                  const selectedDsp = dsps.find(dsp => dsp.dsp_id === e.target.value);
                  if (selectedDsp) {
                    setValue("pad.dsp", selectedDsp.dsp_id);
                    field.onChange(e);
                    setSelectedValue([]);
                    setValue("pad.setup_criteria_list", []);
                    setSeats([]);
                    setInputValue("");
                  }
                }}
                ref={field.ref}
              >
                {dsps.map((dsp) => (
                  <MenuItem value={dsp.dsp_id} key={dsp.dsp_id}>
                    {dsp.dsp_name}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          )}
        />
        <Controller
          name="pad.setup_criteria"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl fullWidth margin="normal">
              <InputLabel id="setupCriteria-tag-select-label">Setup Criteria</InputLabel>
              <Select
                labelId="setupCriteria-tag-select-label"
                error={!!fieldState.error}
                disabled={isEditing}
                label="Setup Criteria"
                value={field.value ?? "seatId"}
                onChange={(e) => {
                  field.onChange(e);
                }}
                ref={field.ref}
              >
                {Object.keys(SetupCriteria).map((key, index) => (
                  <MenuItem value={key} key={index}>
                    {SetupCriteria[key]}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          )}
        />
        <Controller
          name="pad.setup_criteria_list"
          control={control}
          rules={{
            required: "Setup Criteria List is Required",
            minLength: { value: 1, message: "Minimum length is 1" },
            maxLength: { value: 200, message: "Maximum length is 200" }
          }}
          render={({ field, fieldState }) => {

            const combinedSeats = [
              ...seats, // Available seats
              ...setupCriteriaList.map(seat_id => ({ seat_id })) // Add selected seats to options
            ];

            const filteredSeats = combinedSeats.filter(
              seat => !setupCriteriaList.includes(seat.seat_id)
            );

            return (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  multiple
                  id="setup-criteria-list"
                  options={filteredSeats}
                  getOptionLabel={(option) => option.seat_id}
                  disableCloseOnSelect
                  PaperComponent={CustomPaper}
                  ListboxComponent={CustomListboxComponent}
                  disabled={!fetchSeats}
                  noOptionsText={getNoOptionsText()}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckboxCheckedIcon />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.seat_id}
                    </li>
                  )}
                  renderTags={(value, getTagProps) => (
                    value.length > 0
                      ? value.map((option, index) => (
                        <Chip label={option.seat_id} {...getTagProps({ index })} />
                      ))
                      : null
                  )}
                  renderInput={(params) => {
                    const { error } = fieldState;
                    const isError = !!error;
                    const message = error ? error.message : "";

                    return (
                    <TextField
                      {...params}
                      label="Setup Criteria List"
                      placeholder={setupCriteriaList.length === 0 ? "Select seats" : ""}
                      disabled={!isActive}
                      error={isError}
                      helperText={isError ? message : ""}
                    />
                    );
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue.trim());
                  }}
                  onChange={(event, value) => {
                    const selectedValues = value.map(item => item.seat_id);
                    field.onChange(selectedValues.length > 0 ? selectedValues : []);
                    setSelectedValue(value);
                    setSeats([]);
                  }}
                  onClose={() => {
                    setSeats([]);
                  }}
                  value={selectedValue}
                />
              </FormControl>
            );
          }}
        />
        <Grid container spacing={11}>
          <Grid item xs={4}>
            <Controller
              name="pad.discount"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth margin="normal">
                  <Typography>Discount</Typography>
                  <TextField
                    {...field}
                    type="number"
                    value={field.value ?? 0.5}
                    disabled={!isActive}
                    onChange={(event) => {
                      let value = parseFloat(event.target.value);
                      if (isNaN(value)) value = 0.5;
                      if (value < 0.5) value = 0.5;
                      if (value > 10) value = 10;
                      field.onChange(value);
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0.5,
                        max: 10,
                        step: 0.5
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            disabled={!isActive}
                            onClick={() => {
                              let value = parseFloat(field.value) - 0.5;
                              if (value < 0.5) value = 0.5;
                              field.onChange(value);
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={!isActive}
                            onClick={() => {
                              let value = parseFloat(field.value) + 0.5;
                              if (value > 10) value = 10;
                              field.onChange(value);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : ""}
                  />
                </FormControl>
              )}
            />

          </Grid>
        </Grid>
        {isEditing && (<Controller
          name="pad.active"
          control={control}
          defaultValue={isActive}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                  color="primary"
                />
              }
              label="Active"
            />
          )}
        />)}
      </CardContent>
    </Card>
  );
}

const CheckboxIcon = () => <span style={{ display: "none" }}></span>;
const CheckboxCheckedIcon = () => <span style={{ display: "none" }}></span>;
